'use client'
import { Drawer, DrawerContent, DrawerFooter, DrawerTrigger } from './ui/drawer'
import React, { SetStateAction, useState } from 'react'
import Link from 'next/link'
import { Button } from './ui/button'
import { Separator } from './ui'
import { AlignJustify, ChevronDown, ChevronUp, UserRoundCog, X } from 'lucide-react'
import { AuthFunction } from '@zent-auth/types/enums/AuthFunction'
import { signOut, useSession } from 'next-auth/react'
import { NEXT_PUBLIC_CONSOLE_CARE_URL, NEXT_PUBLIC_CONSOLE_OP_URL } from '@zent-auth/configs/ZENT_ENV'

enum MENU_CATEGORY {
  REFUND,
  SALES,
  CARE,
  CARE_PRO_BUSINESS,
  CARE_PRO_TAXPAYERS,
  CARE_PRO_BOOK,
  CARE_PRO_PAYROLL,
  CARE_PRO_CERTIFICATION,
  CARE_PRO_FILE,
  CARE_PRO_ALARM,
  CARE_PRO_CRM
}

const RootNavBar = ({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: SetStateAction<any> }) => {
  const checkInitialMenuCategoryOpen = () => {
    const { pathname } = document.location
    if (pathname.includes('/refund/') || pathname.includes('/csform/')) {
      return MENU_CATEGORY.REFUND
    } else if (pathname.includes('/sales')) {
      return MENU_CATEGORY.SALES
    } else if (pathname === '/subscriptions') {
      return MENU_CATEGORY.CARE
    } else if (pathname.includes('/pro/org')) {
      return MENU_CATEGORY.CARE_PRO_BUSINESS
    } else if (pathname.includes('/pro/taxpayers') || pathname === '/pro/task') {
      return MENU_CATEGORY.CARE_PRO_TAXPAYERS
    } else if (pathname.includes('/pro/book')) {
      return MENU_CATEGORY.CARE_PRO_BOOK
    } else if (pathname.includes('/pro/payroll')) {
      return MENU_CATEGORY.CARE_PRO_PAYROLL
    } else if (pathname === '/pro/documents' || pathname === '/pro/certificate') {
      return MENU_CATEGORY.CARE_PRO_CERTIFICATION
    } else if (pathname.includes('/pro/files')) {
      return MENU_CATEGORY.CARE_PRO_FILE
    } else if (pathname.includes('/pro/report')) {
      return MENU_CATEGORY.CARE_PRO_ALARM
    } else if (pathname.includes('/pro/crm')) {
      return MENU_CATEGORY.CARE_PRO_CRM
    } else {
      return undefined
    }
  }

  const [categoryOpen, setCategoryOpen] = useState<MENU_CATEGORY | undefined>(checkInitialMenuCategoryOpen())
  const { data } = useSession()

  const doSignout = async () => {
    await signOut()
  }

  const categoryClassName = 'font-bold mb-1 rounded-md px-2 py-1 flex justify-between cursor-pointer'
  const itemClassName = 'block mb-1 px-3 py-1.5 hover:bg-neutral-100 dark:hover:bg-neutral-900'

  const categoryOpenClose = (targetCategory: MENU_CATEGORY) => {
    if (targetCategory === categoryOpen) {
      setCategoryOpen(undefined)
    } else {
      setCategoryOpen(targetCategory)
    }
  }

  return (
    <Drawer direction="left" modal={false} open={isOpen}>
      <DrawerTrigger asChild>
        <Button variant="outline" size="icon" className="mx-2 my-4" onClick={() => setIsOpen(true)}>
          <AlignJustify className="w-4 h-4" />
        </Button>
      </DrawerTrigger>
      <DrawerContent className="h-[100vh] max-w-64 rounded-none overflow-y-scroll">
        <div className="flex justify-between break-word">
          <div className="w-full">
            <div className="flex justify-end">
              <Button variant="ghost" size="icon" className="rounded-none" onClick={() => setIsOpen(false)}>
                <X className="w-4 h-4" />
              </Button>
            </div>
            <Separator orientation="horizontal" />
            {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_REFUND) && (
              <>
                <div>
                  <div className={categoryClassName} onClick={() => categoryOpenClose(MENU_CATEGORY.REFUND)}>
                    <div>종소세 경정청구</div>
                    {categoryOpen === MENU_CATEGORY.REFUND ? <ChevronUp /> : <ChevronDown />}
                  </div>
                  <div className={categoryOpen === MENU_CATEGORY.REFUND ? 'block' : 'hidden'}>
                    <div>
                      <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/refund`}>
                        분석상세
                      </Link>
                    </div>
                    <div>
                      <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/csform/small-business`}>
                        중특감 설문결과
                      </Link>
                    </div>
                    <div>
                      <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/csform/startup-business`}>
                        창중감 설문결과
                      </Link>
                    </div>
                  </div>
                </div>
                <Separator orientation="horizontal" />
              </>
            )}
            {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_SALES) && (
              <>
                <div>
                  <div className={categoryClassName} onClick={() => categoryOpenClose(MENU_CATEGORY.SALES)}>
                    <div>영업관리</div>
                    {categoryOpen === MENU_CATEGORY.SALES ? <ChevronUp /> : <ChevronDown />}
                  </div>
                  <div className={categoryOpen === MENU_CATEGORY.SALES ? 'block' : 'hidden'}>
                    <div>
                      <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/sales/channels`}>
                        영업채널 관리
                      </Link>
                    </div>
                    <div>
                      <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/sales/salesman`}>
                        영업자 관리
                      </Link>
                    </div>
                    <div>
                      <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/sales`}>
                        영업 현황
                      </Link>
                    </div>
                    <div>
                      <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/sales/settlement`}>
                        정산관리
                      </Link>
                    </div>
                  </div>
                </div>
                <Separator orientation="horizontal" />
              </>
            )}
            {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_ONEMIN) && (
              <>
                <div>
                  <div>
                    <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/1min`}>
                      1분 환급
                    </Link>
                  </div>
                </div>
                <Separator orientation="horizontal" />
              </>
            )}
            {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_CORPTAX) && (
              <>
                <div>
                  <div>
                    <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/corptax`}>
                      법인세 경정청구
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <Separator orientation="horizontal" />
        {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_CARE_SUBSCRIPTION) && (
          <>
            <div>
              <div className={categoryClassName} onClick={() => categoryOpenClose(MENU_CATEGORY.CARE)}>
                <div>비즈넵 케어</div>
                {categoryOpen === MENU_CATEGORY.CARE ? <ChevronUp /> : <ChevronDown />}
              </div>
              <div className={categoryOpen === MENU_CATEGORY.CARE ? 'block' : 'hidden'}>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/subscription`}>
                  케어 구독현황
                </Link>
              </div>
            </div>
          </>
        )}
        {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_CARE_PRO) && (
          <>
            <Separator orientation="horizontal" />
            <div>
              <div className={categoryClassName} onClick={() => categoryOpenClose(MENU_CATEGORY.CARE_PRO_BUSINESS)}>
                <div>케어프로-사업장 관리</div>{' '}
                {categoryOpen === MENU_CATEGORY.CARE_PRO_BUSINESS ? <ChevronUp /> : <ChevronDown />}
              </div>
              <div className={categoryOpen === MENU_CATEGORY.CARE_PRO_BUSINESS ? 'block' : 'hidden'}>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/org`}>
                  수임사업장 관리
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/org/cms`}>
                  출금동의서 조회
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/org/info`}>
                  사업장정보 조회
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/org/taxinfo`}>
                  사업장 휴폐업 일괄조회
                </Link>
              </div>
            </div>
            <Separator orientation="horizontal" />
            <div>
              <div className={categoryClassName} onClick={() => categoryOpenClose(MENU_CATEGORY.CARE_PRO_TAXPAYERS)}>
                <div>케어프로-업무 관리</div>{' '}
                {categoryOpen === MENU_CATEGORY.CARE_PRO_TAXPAYERS ? <ChevronUp /> : <ChevronDown />}
              </div>
              <div className={categoryOpen === MENU_CATEGORY.CARE_PRO_TAXPAYERS ? 'block' : 'hidden'}>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/task`}>
                  작업 관리
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/taxpayer`}>
                  종소세 신고서 관리
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/taxpayer/vat`}>
                  부가세 신고자 관리
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/taxpayer/documents`}>
                  홈택스 접수증/납부서
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/taxpayer/wetax/documents`}>
                  위택스 접수증/납부서
                </Link>
              </div>
            </div>
            <Separator orientation="horizontal" />
            <div>
              <div className={categoryClassName} onClick={() => categoryOpenClose(MENU_CATEGORY.CARE_PRO_BOOK)}>
                <div>케어프로-장부/부가세</div>
                {categoryOpen === MENU_CATEGORY.CARE_PRO_BOOK ? <ChevronUp /> : <ChevronDown />}
              </div>
              <div className={categoryOpen === MENU_CATEGORY.CARE_PRO_BOOK ? 'block' : 'hidden'}>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/book`}>
                  장부작성
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/book/bill/duplicate`}>
                  중복증빙조회
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/book/status`}>
                  장부 요약 분석
                </Link>
                <Separator orientation="horizontal" />
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/book/bill/summary`}>
                  증빙합계조회(홈택스)
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/book/vat/service`}>
                  신고도움조회(홈택스)
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/book/declare/vat`}>
                  부가세신고
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/book/declare/taxfree`}>
                  면세사업장현황신고
                </Link>
                <Separator orientation="horizontal" />
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/book/card`}>
                  사업자신용카드 등록/조회
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/book/car`}>
                  차량조회
                </Link>
              </div>
            </div>
            <Separator orientation="horizontal" />

            <div>
              <div className={categoryClassName} onClick={() => categoryOpenClose(MENU_CATEGORY.CARE_PRO_PAYROLL)}>
                <div>케어프로-급여/원천세</div>
                {categoryOpen === MENU_CATEGORY.CARE_PRO_PAYROLL ? <ChevronUp /> : <ChevronDown />}
              </div>
              <div className={categoryOpen === MENU_CATEGORY.CARE_PRO_PAYROLL ? 'block' : 'hidden'}>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/employee`}>
                  직원명부
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/4insure`}>
                  4대보험
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/response`}>
                  급여제출내역
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/salary/beforetax`}>
                  근로소득
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/daily`}>
                  일용소득
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/biz`}>
                  사업소득
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/etc`}>
                  기타소득
                </Link>
                <Separator orientation="horizontal" />
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/withholding`}>
                  원천징수신고서(월)
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/withholding/half`}>
                  원천징수신고서(반기)
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/payment`}>
                  간이지급명세서(월)
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/payment/employee`}>
                  간이지급명세서(반기)
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/payment/year`}>
                  지급명세서
                </Link>
              </div>
            </div>
            <Separator orientation="horizontal" />

            <div>
              <div
                className={categoryClassName}
                onClick={() => categoryOpenClose(MENU_CATEGORY.CARE_PRO_CERTIFICATION)}
              >
                <div>케어프로-증명/신고서</div>
                {categoryOpen === MENU_CATEGORY.CARE_PRO_CERTIFICATION ? <ChevronUp /> : <ChevronDown />}
              </div>
              <div className={categoryOpen === MENU_CATEGORY.CARE_PRO_CERTIFICATION ? 'block' : 'hidden'}>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/documents`}>
                  신고서조회(전체)
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/certificate`}>
                  증명발급
                </Link>
              </div>
            </div>
            <Separator orientation="horizontal" />
            <div>
              <div className={categoryClassName} onClick={() => categoryOpenClose(MENU_CATEGORY.CARE_PRO_FILE)}>
                <div>케어프로-파일관리</div>
                {categoryOpen === MENU_CATEGORY.CARE_PRO_FILE ? <ChevronUp /> : <ChevronDown />}
              </div>
              <div className={categoryOpen === MENU_CATEGORY.CARE_PRO_FILE ? 'block' : 'hidden'}>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/files`}>
                  파일관리
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/files/submission`}>
                  제출내역
                </Link>
              </div>
            </div>
            <Separator orientation="horizontal" />
            <div>
              <div className={categoryClassName} onClick={() => categoryOpenClose(MENU_CATEGORY.CARE_PRO_ALARM)}>
                <div>케어프로-알림톡 발송</div>
                {categoryOpen === MENU_CATEGORY.CARE_PRO_ALARM ? <ChevronUp /> : <ChevronDown />}
              </div>
              <div className={categoryOpen === MENU_CATEGORY.CARE_PRO_ALARM ? 'block' : 'hidden'}>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/chat`}>
                  가입상담
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/apply`}>
                  가입신청(상담완료)
                </Link>
                <Separator orientation="horizontal" />
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/notpaid`}>
                  기장료 미수안내
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/notpaidlong`}>
                  기장료 미수안내(기장종료)
                </Link>
                <Separator orientation="horizontal" />
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/monthly`}>
                  월간매출매입보고서
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/calendar`}>
                  세무일정
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/bizcard`}>
                  사업용신용카드
                </Link>
                <Separator orientation="horizontal" />
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/payroll/request/new`}>
                  급여제출요청(통합)
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/payroll/request`}>
                  급여제출요청(알바)
                </Link>
                <Link
                  className={itemClassName}
                  href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/payroll/regular/request`}
                >
                  급여제출요청(정규직)
                </Link>
                <Separator orientation="horizontal" />
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/wedbill/request`}>
                  경조사비제출요청
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/etcbill/request`}>
                  영수증제출요청
                </Link>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/incometax/guide`}>
                  종합소득세신고안내문(기장)
                </Link>
                <Link
                  className={itemClassName}
                  href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/incometax/guide/declare`}
                >
                  종합소득세신고안내문(신고)
                </Link>
                <Separator orientation="horizontal" />
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/prevat`}>
                  부가세중간예납안내
                </Link>
              </div>
            </div>
            <Separator orientation="horizontal" />
            <div>
              <div className={categoryClassName} onClick={() => categoryOpenClose(MENU_CATEGORY.CARE_PRO_CRM)}>
                <div>케어프로-CRM관리</div>
                {categoryOpen === MENU_CATEGORY.CARE_PRO_FILE ? <ChevronUp /> : <ChevronDown />}
              </div>
              <div className={categoryOpen === MENU_CATEGORY.CARE_PRO_CRM ? 'block' : 'hidden'}>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/crm`}>
                  CRM 목록
                </Link>
              </div>
            </div>
            <Separator orientation="horizontal" />
          </>
        )}
        <div>
          <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/externals`}>
            외부 링크
          </Link>
        </div>
        <Separator orientation="horizontal" />
        <div>
          <a className={itemClassName} href="https://bznav.com/" target="_blank">
            비즈넵(SME)
          </a>
        </div>
        <div>
          <a className={itemClassName} href="https://bznav.com/tax/refund" target="_blank">
            비즈넵 환급
          </a>
        </div>
        <div>
          <a className={itemClassName} href="https://care.bznav.com/" target="_blank">
            비즈넵 케어
          </a>
        </div>
        <div>
          <a className={itemClassName} href="https://help.bznav.com/hc/ko" target="_blank">
            비즈넵 고객센터(구 헬프센터)
          </a>
        </div>
        <div>
          <a className={itemClassName} href="https://1min.bznav.com/ " target="_blank">
            1분
          </a>
        </div>
        {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_CARE_PRO) && (
          <div>
            <a className={itemClassName} href="https://ai.bznav.com/payroll/edit " target="_blank">
              간이 급여명세서
            </a>
            <div>
              <a className={itemClassName} href="https://ai.livein.cloud/ocr " target="_blank">
                증빙 AI 추출
              </a>
            </div>
            <div>
              <a className={itemClassName} href="https://ai.bznav.com/ " target="_blank">
                세나 AI
              </a>
            </div>
          </div>
        )}
        <Separator orientation="horizontal" />
        {data && data.user && data.user?.functions.includes('PAGE_ADMIN_USER') && (
          <div>
            <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/admin/users`}>
              <div className="flex items-center">
                <UserRoundCog className="w-4 h-4 mr-2" />
                콘솔 권한 관리
              </div>
            </Link>
          </div>
        )}

        <DrawerFooter>
          <Button variant="secondary" onClick={doSignout}>
            로그아웃
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
export default RootNavBar
